import React from "react";
import "./Home.css";

function Facts() {
  return (
    <section className="facts">
      <div className="container-1">
        <h2>Facts</h2>
        <div class="container">
          <table>
            <tr>
              <th>Tank types</th>
              <td>Mild steel</td>
            </tr>
            <tr>
              <th>Tank size</th>
              <td>From 2,000 to 100,000 cbm</td>
            </tr>
            <tr>
              <th>Access</th>
              <td>Barge, Pipeline, Vessel</td>
            </tr>
            <tr>
              <th>Berths for barges</th>
              <td>15</td>
            </tr>
            <tr>
              <th>Berths for vessels</th>
              <td>7</td>
            </tr>
            <tr>
              <th>Products</th>
              <td>Crude oil, Petroleum products</td>
            </tr>
            <tr>
              <th>Services</th>
              <td>Blending, Heating, Dedicated systems, Additives</td>
            </tr>
            <tr>
              <th>Certification</th>
              <td>ISCC+, ISCC EU</td>
            </tr>
            <tr>
              <th>Terminal type</th>
              <td>Hub, Import-Export-Distribution, Industrial/Co Siting</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="contact-section">
        <h2>Contact</h2>
        <div class="contact-details">
            <div>
                <h3>Address</h3>
                <address>
                Haarlemmerweg 331, 1051 <br></br> LG Amsterdam, Netherlands
                </address>
            </div>
            <div>
                <h3>Phone</h3>
                <p>+31 (97) 0050 32 575</p>
                <p> Rottank Storage and Logistics</p>
            </div>
        </div>
    </div>
   
    </section>
  );
}

export default Facts;
